import { SpanAttributes } from './attributes.js';
import { SpanInternal, coreSpanOptionSchema } from './span.js';
import { timeToNumber } from './time.js';
import { isParentContext, isObject } from './validation.js';

const DISCARD_END_TIME = -1;
class SpanFactory {
    constructor(processor, sampler, idGenerator, spanAttributesSource, clock, backgroundingListener, logger, spanContextStorage) {
        this.openSpans = new WeakSet();
        this.isInForeground = true;
        this.onBackgroundStateChange = (state) => {
            this.isInForeground = state === 'in-foreground';
            // clear all open spans regardless of the new background state
            // since spans are only valid if they start and end while the app is in the foreground
            this.openSpans = new WeakSet();
        };
        this.processor = processor;
        this.sampler = sampler;
        this.idGenerator = idGenerator;
        this.spanAttributesSource = spanAttributesSource;
        this.clock = clock;
        this.logger = logger;
        this.spanContextStorage = spanContextStorage;
        // this will fire immediately if the app is already backgrounded
        backgroundingListener.onStateChange(this.onBackgroundStateChange);
    }
    startSpan(name, options) {
        const safeStartTime = timeToNumber(this.clock, options.startTime);
        const spanId = this.idGenerator.generate(64);
        // if the parentContext option is not set use the current context
        // if parentContext is explicitly null, or there is no current context,
        // we are starting a new root span
        const parentContext = isParentContext(options.parentContext) || options.parentContext === null
            ? options.parentContext
            : this.spanContextStorage.current;
        const parentSpanId = parentContext ? parentContext.id : undefined;
        const traceId = parentContext ? parentContext.traceId : this.idGenerator.generate(128);
        const attributes = new SpanAttributes(new Map());
        if (typeof options.isFirstClass === 'boolean') {
            attributes.set('bugsnag.span.first_class', options.isFirstClass);
        }
        const span = new SpanInternal(spanId, traceId, name, safeStartTime, attributes, parentSpanId);
        // don't track spans that are started while the app is backgrounded
        if (this.isInForeground) {
            this.openSpans.add(span);
            if (options.makeCurrentContext !== false) {
                this.spanContextStorage.push(span);
            }
        }
        return span;
    }
    startNetworkSpan(options) {
        const spanName = `[HTTP/${options.method.toUpperCase()}]`;
        const cleanOptions = this.validateSpanOptions(spanName, options);
        const spanInternal = this.startSpan(cleanOptions.name, Object.assign(Object.assign({}, cleanOptions.options), { makeCurrentContext: false }));
        spanInternal.setAttribute('bugsnag.span.category', 'network');
        spanInternal.setAttribute('http.method', options.method);
        spanInternal.setAttribute('http.url', options.url);
        return spanInternal;
    }
    configure(processor, logger) {
        this.processor = processor;
        this.logger = logger;
    }
    endSpan(span, endTime, additionalAttributes) {
        // if the span doesn't exist here it shouldn't be processed
        if (!this.openSpans.delete(span)) {
            // only warn if the span has already been ended explicitly rather than
            // discarded by us
            if (!span.isValid()) {
                this.logger.warn('Attempted to end a Span which has already ended.');
            }
            return;
        }
        // Discard marked spans
        if (endTime === DISCARD_END_TIME)
            return;
        // Set any additional attributes
        for (const [key, value] of Object.entries(additionalAttributes || {})) {
            span.setAttribute(key, value);
        }
        this.spanAttributesSource.requestAttributes(span);
        const spanEnded = span.end(endTime, this.sampler.spanProbability);
        this.spanContextStorage.pop(span);
        if (this.sampler.sample(spanEnded)) {
            this.processor.add(spanEnded);
        }
    }
    toPublicApi(span) {
        return {
            get id() {
                return span.id;
            },
            get traceId() {
                return span.traceId;
            },
            get samplingRate() {
                return span.samplingRate;
            },
            isValid: () => span.isValid(),
            end: (endTime) => {
                const safeEndTime = timeToNumber(this.clock, endTime);
                this.endSpan(span, safeEndTime);
            }
        };
    }
    validateSpanOptions(name, options, schema = coreSpanOptionSchema) {
        let warnings = '';
        const cleanOptions = {};
        if (typeof name !== 'string') {
            warnings += `\n  - name should be a string, got ${typeof name}`;
            name = String(name);
        }
        if (options !== undefined && !isObject(options)) {
            warnings += '\n  - options is not an object';
        }
        else {
            const spanOptions = options || {};
            for (const option of Object.keys(schema)) {
                if (Object.prototype.hasOwnProperty.call(spanOptions, option) && spanOptions[option] !== undefined) {
                    if (schema[option].validate(spanOptions[option])) {
                        cleanOptions[option] = spanOptions[option];
                    }
                    else {
                        warnings += `\n  - ${option} ${schema[option].message}, got ${typeof spanOptions[option]}`;
                        cleanOptions[option] = schema[option].getDefaultValue(spanOptions[option]);
                    }
                }
                else {
                    cleanOptions[option] = schema[option].getDefaultValue(spanOptions[option]);
                }
            }
        }
        if (warnings.length > 0) {
            this.logger.warn(`Invalid span options${warnings}`);
        }
        return { name, options: cleanOptions };
    }
}

export { DISCARD_END_TIME, SpanFactory };
