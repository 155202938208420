import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RoutesRecognized } from '@angular/router';
import { PremiumTagComponent } from '@commons/premium-tag/premium-tag.component';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import { BurgerButtonMenuComponent } from '@domains/burger-menu/burger-button-menu/burger-button-menu.component';
import {
  LegalGuardianLoggedInEntries,
  LegalGuardianLoggedMainEntries,
  UserLoggedInEntries,
  UserLoggedMainEntries,
} from '@domains/burger-menu/burger-menu-entries-config/burger-menu-entries';
import { BurgerMenuItemComponent } from '@domains/burger-menu/burger-menu-item/burger-menu-item.component';
import { CoPlatformMainMenuCardComponent } from '@domains/burger-menu/co-platform-main-menu-card/co-platform-main-menu-card.component';
import { MainHeaderToolbarComponent } from '@domains/main-header/main-header-toolbar/main-header-toolbar.component';
import { FEATURES_ROUTING, FeaturesRoutingType } from '@features/features.routing';
import { GUARDIAN_ROUTING } from '@features/guardian/guardian.routing';
import { PROFILE_ROUTING, ProfileRoutingType } from '@features/profile/profile.routing';
import { getIsLockedAndMinimumRequiredPlanLabel } from '@global/utils/utils';
import { LegalStatusEnum, Plan, Profile } from '@loyalty-v3/libs';
import { MenuActions, UserLoggedInMenuEntry } from '@models/burger-menu/burger-menu-entries.models';
import { BurgerMenuService } from '@models/burger-menu/burger-menu-service.interface';
import { Service } from '@models/services/service';
import { Theme } from '@models/themes/theme';
import { Store } from '@ngrx/store';
import { hasBankingProducts } from '@stores/banking-products/banking-products.selectors';
import { isCommunicationPageActive } from '@stores/communications/communications.selectors';
import { allowUpsellWithoutPayment } from '@stores/feature-flags/feature-flags.selectors';
import { closeBurgerPanel } from '@stores/panel/panel.actions';
import * as FromPartnerSelectors from '@stores/partner/partner.selectors';
import { userPlans } from '@stores/plan/plan.selectors';
import { shouldShowNotificationTab } from '@stores/profile/profile.selectors';
import { platformHasUpsell } from '@stores/selectors/has-upsell.selectors';
import { hasTopPlan, userWithPlanDetails } from '@stores/selectors/profile-plans.selector';
import { userServices } from '@stores/services/services.selector';
import { selectThemes } from '@stores/themes/themes.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { isLogged, jwtDelete } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';
import { Observable, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    CoPlatformMainMenuCardComponent,
    BurgerMenuItemComponent,
    RouterLink,
    RouterLinkActive,
    PremiumTagComponent,
    BurgerButtonMenuComponent,
    MainHeaderToolbarComponent,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class BurgerMenuComponent implements OnInit {
  readonly currentRouteSig = signal<FeaturesRoutingType | ProfileRoutingType | null>(null);
  readonly UserLoggedInEntries = UserLoggedInEntries;
  readonly LegalGuardianLoggedInEntries = LegalGuardianLoggedInEntries;

  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);

  readonly #platformHasUpsell$ = selectIfDefined(this.#store, platformHasUpsell);
  readonly #isCommunicationPageActiveSig = this.#store.selectSignal(isCommunicationPageActive);
  readonly #hasBankingProducts$ = selectIfDefined(this.#store, hasBankingProducts);
  readonly #userPlans$ = selectIfDefined(this.#store, userPlans);
  readonly #userServices$ = selectIfDefined(this.#store, userServices);
  readonly #isLoggedIn$ = this.#store.select(isLogged);
  readonly #profile$ = selectIfDefined(this.#store, userWithPlanDetails);
  readonly #partner$ = this.#store.select(FromPartnerSelectors.selectPartner);
  readonly #hasTopPlan$ = this.#store.select(hasTopPlan);
  readonly #allowUpsellWithoutPayment$ = selectIfDefined(this.#store, allowUpsellWithoutPayment);
  readonly #hasCoPlatform$ = this.#store.select(FromPartnerSelectors.hasCoPlatform);
  readonly #shouldShowNotificationEntry = this.#store.selectSignal(shouldShowNotificationTab);

  readonly #isLegalGuardian$ = this.#profile$.pipe(
    map((profile) => profile.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN),
    distinctUntilChanged()
  );

  readonly userLoggedInEntries = computed(() => {
    const shouldShowNotification = this.#shouldShowNotificationEntry();

    if (!shouldShowNotification) {
      return UserLoggedInEntries.filter((entry) => (entry.id as ProfileRoutingType) !== PROFILE_ROUTING.notifications);
    }

    return UserLoggedInEntries;
  });

  readonly #userLoggedInMainMenu$ = combineLatest([
    this.#isLegalGuardian$,
    this.#platformHasUpsell$,
    this.#hasTopPlan$,
    this.#allowUpsellWithoutPayment$,
    this.#hasBankingProducts$,
    this.#partner$,
  ]).pipe(
    map(([isLegalGuardian, hasUpsell, userIsTopPlan, allowUpsellWithoutPayment, hasBankingProducts, partner]) => {
      if (isLegalGuardian) {
        return LegalGuardianLoggedMainEntries;
      }

      const userLoggedInEntries = [...UserLoggedMainEntries];

      if (!hasBankingProducts) {
        const index = userLoggedInEntries.findIndex(
          (entry) => (entry.id as FeaturesRoutingType) === FEATURES_ROUTING.bankingProducts
        );
        userLoggedInEntries.splice(index, 1);
      }

      if (hasBankingProducts && partner?.partnerConfiguration.labels.mainMenu?.offersLabel) {
        const index = userLoggedInEntries.findIndex(
          (entry) => (entry.id as FeaturesRoutingType) === FEATURES_ROUTING.bankingProducts
        );
        if (userLoggedInEntries[index] && partner?.partnerConfiguration?.labels?.mainMenu?.offersLabel) {
          userLoggedInEntries[index].label = partner?.partnerConfiguration.labels.mainMenu.offersLabel;
        }
      }
      if (!this.#isCommunicationPageActiveSig()) {
        const index = userLoggedInEntries.findIndex(
          (entry) => (entry.id as FeaturesRoutingType) === FEATURES_ROUTING.communications
        );
        userLoggedInEntries.splice(index, 1);
      }

      if (!hasUpsell) {
        return userLoggedInEntries.filter((entry) => (entry.id as FeaturesRoutingType) !== FEATURES_ROUTING.upsell);
      }

      if (allowUpsellWithoutPayment && userIsTopPlan) {
        return userLoggedInEntries.filter((entry) => (entry.id as FeaturesRoutingType) !== FEATURES_ROUTING.upsell);
      }

      return userLoggedInEntries;
    })
  );

  readonly #themes$ = selectIfDefined(this.#store, selectThemes);

  readonly #services$ = combineLatest([this.#userServices$, this.#profile$, this.#userPlans$, this.#themes$]).pipe(
    map(([services, profile, plans, themes]) => this.#getUserServices({ services, profile, plans, themes }))
  );

  readonly vm$: Observable<{
    userLoggedInMainEntries: UserLoggedInMenuEntry[];
    isLegalGuardian: boolean;
    hasUpsell: boolean;
    isLoggedIn: boolean;
    hasTopPlan: boolean;
    services: BurgerMenuService[];
    hasCoPlatform: boolean;
  }> = combineLatest([
    this.#userLoggedInMainMenu$,
    this.#isLegalGuardian$,
    this.#platformHasUpsell$,
    this.#isLoggedIn$,
    this.#services$,
    this.#profile$,
    this.#hasCoPlatform$,
  ]).pipe(
    map(([userLoggedInMainEntries, isLegalGuardian, hasUpsell, isLoggedIn, services, profile, hasCoPlatform]) => ({
      hasTopPlan: profile.hasTopPlan || false,
      userLoggedInMainEntries,
      isLegalGuardian,
      hasUpsell,
      isLoggedIn,
      services: this.#reorderService(services),
      hasCoPlatform,
      arrowLeft: LoyaltyIcons.ArrowLeft,
      cmCicOeoFlag: null,
    }))
  );

  readonly FeaturesRouting = FEATURES_ROUTING;
  readonly GuardianRouting = GUARDIAN_ROUTING;

  readonly trackEvent = trackEvent;

  ngOnInit(): void {
    this.#router.events
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((event): event is RoutesRecognized => event instanceof NavigationEnd),
        map((event) => event.urlAfterRedirects.split('/')),
        map((urlSplittedArray) => this.#parseUrl(urlSplittedArray))
      )
      .subscribe({
        next: (routes) => {
          this.#setActiveLink(routes);
        },
      });
  }

  #reorderService(services: BurgerMenuService[]): BurgerMenuService[] {
    return services.toSorted(({ index: index_a }, { index: index_b }) => {
      if (index_a === null || index_b === null) {
        return 0;
      }
      if (index_a > index_b) {
        return 1;
      }

      if (index_a < index_b) {
        return -1;
      }

      return 0;
    });
  }

  #parseUrl(urlSplittedArray: string[]): { route: FeaturesRoutingType; childRoute: ProfileRoutingType } {
    const [route, childRoute] = urlSplittedArray.slice(1); // remove root route

    return { route, childRoute } as unknown as { route: FeaturesRoutingType; childRoute: ProfileRoutingType }; //transform type
  }

  #setActiveLink({ route, childRoute }: { route: FeaturesRoutingType; childRoute: ProfileRoutingType }): void {
    if (route === FEATURES_ROUTING.services) {
      return this.currentRouteSig.set(FEATURES_ROUTING.services);
    }

    if ([PROFILE_ROUTING.infos].includes(childRoute as typeof PROFILE_ROUTING.infos)) {
      return this.currentRouteSig.set(FEATURES_ROUTING.profile);
    }

    if (childRoute) {
      return this.currentRouteSig.set(childRoute);
    }

    return this.currentRouteSig.set(route as FeaturesRoutingType);
  }

  readonly #getUserServices = ({
    services,
    profile,
    plans,
    themes,
  }: {
    services: Service[];
    profile: Profile;
    plans: Plan[];
    themes: Theme[];
  }): BurgerMenuService[] => {
    const servicesMapped = services.map((service) => {
      const { isLocked, minimumRequiredPlanLabel } = getIsLockedAndMinimumRequiredPlanLabel(service, profile, plans);
      return {
        isLocked,
        minimumRequiredPlanLabel,
        description: service.labels.purposeSentence,
        name: service.name,
        id: service.id,
        icon: service.assets.icon,
        title: service.labels.purposeShortDescription,
        themeId: this.#getThemeId(service, themes),
        index: service.index,
      };
    });

    return servicesMapped;
  };

  readonly #getThemeId = (service: Service, themes: Theme[]): string =>
    themes.find((theme) => theme.serviceIds.includes(service.id))?.id ?? '';

  onMenuEntryClicked(actions: MenuActions[], event?: Event): void {
    event?.preventDefault();
    event?.stopPropagation();
    if (actions.includes('close')) {
      this.closePanel();
    }
    if (actions.includes('logout')) {
      this.logout();
    }
  }

  closePanel(): void {
    this.#store.dispatch(closeBurgerPanel());
  }

  logout(): void {
    this.#store.dispatch(jwtDelete());
  }

  onScroll($event: Event): void {
    $event.stopImmediatePropagation();
  }
}
