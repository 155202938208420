<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (vm$ | async; as vm) {
  <div>
    <app-main-header-toolbar />

    <nav
      class="burger-menu"
      id="burger-menu"
      (appPressEscapeKey)="closePanel()"
      role="navigation"
      aria-label="Menu de navigation"
    >
      @if (vm.services.length) {
        <div class="burger-menu__scrollable burger-menu__scrollable--is-closed" (scroll)="onScroll($event)">
          <ng-container *ngTemplateOutlet="services_list" />
        </div>
      }
      <div class="burger-menu--container">
        <nav class="burger-menu__main burger-menu__main--front" role="navigation" aria-label="Navigation autres pages">
          @if (vm.hasCoPlatform) {
            <app-co-platform-main-menu-card class="burger-menu__main__card" />
          }
          <div class="burger-menu__main__mobile-services">
            @if (vm.services.length > 0) {
              <ng-container *ngTemplateOutlet="services_list" />
            }
          </div>
          @if (vm.isLegalGuardian) {
            <ul role="menu">
              <li class="burger-menu__main__entry">
                <a
                  [routerLink]="[FeaturesRouting.dashboard]"
                  (click)="closePanel(); trackEvent('menu', 'click', '[menu-rl] mes enfants')"
                  routerLinkActive="active-link"
                  role="menuitem"
                  >Accueil
                </a>
              </li>
              @if (vm.hasUpsell) {
                <li class="burger-menu__main__entry">
                  <a
                    [routerLink]="['/', FeaturesRouting.upsell]"
                    (click)="closePanel(); trackEvent('menu', 'click', '[menu-rl] formule')"
                    routerLinkActive="active-link"
                    role="menuitem"
                    >Formule</a
                  >
                </li>
              }
              <li class="burger-menu__main__entry">
                <a
                  [routerLink]="['/', FeaturesRouting.guardian, GuardianRouting.dashboard]"
                  (click)="closePanel(); trackEvent('menu', 'click', '[menu-rl] infos')"
                  role="menuitem"
                  routerLinkActive="active-link"
                  >Mes informations</a
                >
              </li>
            </ul>
          } @else {
            <ul class="burger-menu__main__entries" role="menu">
              @for (mainEntry of vm.userLoggedInMainEntries; track mainEntry.label) {
                <li class="burger-menu__main__entry">
                  @if (mainEntry.route) {
                    <a
                      [attr.aria-label]="'Aller à la page ' + mainEntry.label"
                      [routerLink]="mainEntry.route"
                      [class.active-link]="mainEntry.highlightedRoute === currentRouteSig()"
                      (click)="
                        onMenuEntryClicked(mainEntry.actions); trackEvent('menu', 'click', mainEntry.trackingLabel)
                      "
                    >
                      {{ mainEntry.label }}
                    </a>
                  } @else {
                    <button
                      class="button-link"
                      [class.active-link]="mainEntry.highlightedRoute === currentRouteSig()"
                      (click)="
                        onMenuEntryClicked(mainEntry.actions); trackEvent('menu', 'click', mainEntry.trackingLabel)
                      "
                      type="button"
                    >
                      {{ mainEntry.label }}
                    </button>
                  }
                </li>
              }
            </ul>
          }
          @if (vm.isLoggedIn) {
            <ng-container *ngTemplateOutlet="loggedInMenu" />
          }
        </nav>
      </div>
    </nav>

    <ng-template #loggedInMenu>
      <ul class="burger-menu__main__connected-actions">
        @for (entry of vm.isLegalGuardian ? LegalGuardianLoggedInEntries : userLoggedInEntries(); track entry.label) {
          <li class="burger-menu__main__connected-actions__entry">
            @if (entry.route) {
              <a
                [attr.aria-label]="'Aller à la page ' + entry.label"
                [routerLink]="entry.route"
                [class.active-link]="entry.highlightedRoute === currentRouteSig()"
                (click)="onMenuEntryClicked(entry.actions); trackEvent('menu', 'click', entry.trackingLabel)"
              >
                {{ entry.label }}
              </a>
            } @else {
              <button
                class="button-link"
                [class.active-link]="entry.highlightedRoute === currentRouteSig()"
                (click)="onMenuEntryClicked(entry.actions); trackEvent('menu', 'click', entry.trackingLabel)"
                type="button"
              >
                {{ entry.label }}
              </button>
            }
            @if (vm.hasTopPlan === false && entry.appendTag) {
              <app-premium-tag
                class="burger-menu__main__connected-actions__entry__premium"
                aria-label="Accéder à la comparaison des plans"
              />
            }
          </li>
        }
      </ul>
    </ng-template>

    <ng-template #services_list>
      <nav aria-label="Navigation menu des services" role="navigation">
        <ul class="burger-menu__list-of-services" role="menu">
          @for (menuService of vm.services; track menuService.id) {
            <li class="burger-menu__list-of-services__item">
              <app-burger-menu-item [service]="menuService" (serviceClicked)="closePanel()" />
            </li>
          }
        </ul>
      </nav>
    </ng-template>
  </div>
}
