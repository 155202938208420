import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NotificationToastComponent } from '@core/components/notification-toast/notification-toast.component';
import { NotificationsService } from '@core/services/notifications.service';

@Component({
  selector: 'app-notifications-panel',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (notificationsSig(); as notifications) {
      @if (notifications.length > 0) {
        <aside class="notifications-panel">
          <ul [@listAnimation]>
            @for (notification of notifications; track notification.id) {
              <li>
                <app-notification-toast
                  [notificationConfig]="notification"
                  (dismissNotification)="dismissNotification($event)"
                  @listAnimation
                />
              </li>
            }
          </ul>
        </aside>
      }
    }
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .notifications-panel {
        position: fixed;
        inset: var(--inset-notification-panel, 0 0 auto 0);
        z-index: 8;
        width: var(--width-notification-panel, 100%);

        @include breakpoints.minWidth('md') {
          --inset-notification-panel: 0 0 auto auto;
          --width-notification-panel: min(95%, 26.25rem);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(
          ':leave',
          [
            style({ opacity: 1, transform: 'translateX(0) scaleX(1.05)' }),
            stagger(60, [animate('0.3s ease-out', style({ opacity: 0, transform: 'translateX(100%) scaleX(0)' }))]),
          ],
          { optional: true }
        ),
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(100%) scaleX(0)' }),
            stagger(60, [animate('0.5s ease-in-out', style({ opacity: 1, transform: 'translateX(0) scaleX(1)' }))]),
          ],
          {
            optional: true,
          }
        ),
      ]),
    ]),
  ],
  imports: [NotificationToastComponent],
})
export class NotificationsPanelComponent {
  readonly #notificationsService = inject(NotificationsService);

  readonly notificationsSig = this.#notificationsService.notificationConfigActionSig;

  readonly dismissNotification = (id: number): void => {
    this.#notificationsService.dismissNotification(id);
  };
}
