import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Renderer2, effect, inject, signal, untracked } from '@angular/core';
import { Store } from '@ngrx/store';
import { hasFreshDeskWidgetFeatureFlag } from '@stores/feature-flags/feature-flags.selectors';
import { freshdeskWidgetId } from '@stores/partner/partner.selectors';
import { isLogged } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';

type WindowWithFreshdesk = Window & {
  fwSettings: {
    widget_id: number;
    locale: 'fr';
  };
  FreshworksWidget: any;
};

@Component({
  selector: 'app-freshdesk-button',
  standalone: true,
  imports: [AsyncPipe],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    @if (widgetIsMounted()) {
      <button
        class="obendy-freshdesk-button"
        (click)="toggleWidget()"
        type="button"
        aria-label="Ouvrir la liste de questions fréquentes"
      >
        &nbsp;
      </button>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreshdeskButtonComponent {
  readonly #renderer = inject(Renderer2);
  readonly #store = inject(Store);

  readonly #widgetId = this.#store.selectSignal(freshdeskWidgetId);
  readonly #userLogged = this.#store.selectSignal(isLogged);
  readonly #hasFreshDeskWidgetFeatureFlag = this.#store.selectSignal(hasFreshDeskWidgetFeatureFlag);
  readonly trackEvent = trackEvent;

  readonly #widgetIsOpen = signal<boolean>(false);
  readonly widgetIsMounted = signal<boolean>(false);

  get #window(): WindowWithFreshdesk {
    return window as any as WindowWithFreshdesk;
  }

  constructor() {
    effect(() => {
      const userIsLogged = this.#userLogged();

      if (!userIsLogged) return;

      const widgetId = this.#widgetId();

      if (typeof widgetId !== 'number') {
        return;
      }

      if (this.#hasFreshDeskWidgetFeatureFlag() === false) {
        return;
      }

      untracked(() => {
        this.#initialize(widgetId);
      });
    });

    effect(() => {
      const widgetIsMounted = this.widgetIsMounted();
      if (!widgetIsMounted) {
        return;
      }
      const widgetIsOpen = this.#widgetIsOpen();

      if (widgetIsOpen) {
        this.trackEvent('widget freshdesk', 'click', 'CTA - Help - open');
      }

      this.#window.FreshworksWidget(widgetIsOpen ? 'open' : 'close');
    });
  }

  protected readonly toggleWidget = () => this.#widgetIsOpen.update((status) => !status);

  #initialize(widgetId: number): void {
    this.#window.fwSettings = {
      widget_id: widgetId,
      locale: 'fr',
    };

    this.#injectFreshWorksWidgetInWindowObject();

    const body = document.querySelector('body');
    if (body === null) {
      return;
    }

    const script = this.#createFreshdeskElement(widgetId);

    this.#renderer.appendChild(body, script);
    this.widgetIsMounted.set(true);
  }

  #createFreshdeskElement(widgetId: number): HTMLScriptElement {
    const script = this.#renderer.createElement('script');

    script.id = 'Freshchat-js-sdk';
    script.async = true;
    script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
    script.onload = () => {
      try {
        this.#window.FreshworksWidget();
      } catch (error) {
        console.log('error.: ', error);
      }
    };
    script.onerror = function (event: any) {
      console.log('error Onerror.: ', event);
    };

    return script;
  }

  #injectFreshWorksWidgetInWindowObject(): void {
    this.#window.FreshworksWidget ||
      (function () {
        if ('function' !== typeof (window as any).FreshworksWidget) {
          let n = function () {
            (n as any)['q'].push(arguments);
          };
          ((n as any)['q'] = []), ((window as any).FreshworksWidget = n);
        }
      })();
  }
}
