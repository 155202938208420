import { FEATURES_ROUTING } from '@features/features.routing';
import { GUARDIAN_ROUTING } from '@features/guardian/guardian.routing';
import { LEGAL_PAGES_ROUTING } from '@features/legal-pages/legal-pages.routing';
import { PROFILE_ROUTING, ProfileRoutingType } from '@features/profile/profile.routing';
import { UserLoggedInEntriesLabelEnum, UserLoggedInMenuEntry } from '@models/burger-menu/burger-menu-entries.models';

export const UserLoggedMainEntries: UserLoggedInMenuEntry[] = [
  {
    id: FEATURES_ROUTING.dashboard,
    label: 'Accueil',
    route: ['/', FEATURES_ROUTING.dashboard],
    highlightedRoute: FEATURES_ROUTING.dashboard,
    actions: ['close'],
    appendTag: false,
    trackingLabel: '[menu] accueil',
  },
  {
    id: FEATURES_ROUTING.services,
    label: UserLoggedInEntriesLabelEnum.SERVICES,
    route: ['/', FEATURES_ROUTING.services],
    highlightedRoute: FEATURES_ROUTING.services,
    actions: ['close'],
    appendTag: false,
    trackingLabel: '[menu] services',
  },
  {
    id: FEATURES_ROUTING.bankingProducts,
    label: 'Nos offres',
    route: ['/', FEATURES_ROUTING.bankingProducts],
    highlightedRoute: FEATURES_ROUTING.bankingProducts,
    actions: ['close'],
    appendTag: false,
    trackingLabel: '[menu] nos offres',
  },
  {
    id: FEATURES_ROUTING.upsell,
    label: 'Formule',
    route: [FEATURES_ROUTING.upsell],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.upsell,
    appendTag: true,
    trackingLabel: '[menu] formule',
  },
  {
    id: FEATURES_ROUTING.communications,
    label: 'Actualités',
    route: ['/', FEATURES_ROUTING.communications],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.communications,
    appendTag: false,
    trackingLabel: '[menu] actualités',
  },
  {
    id: FEATURES_ROUTING.profile,
    label: UserLoggedInEntriesLabelEnum.PROFIL,
    route: ['/', FEATURES_ROUTING.profile],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.profile,
    appendTag: false,
    trackingLabel: '[menu] profile',
  },
];

export const UserLoggedInEntries: UserLoggedInMenuEntry[] = [
  {
    id: LEGAL_PAGES_ROUTING.confidentiality,
    label: 'Confidentialité',
    route: ['/', FEATURES_ROUTING.legalPages, LEGAL_PAGES_ROUTING.confidentiality],
    actions: ['close'],
    highlightedRoute: LEGAL_PAGES_ROUTING.confidentiality,
    appendTag: false,
    trackingLabel: '[menu] confidentialité',
  },
  {
    id: PROFILE_ROUTING.notifications,
    label: 'Notifications',
    route: ['/', FEATURES_ROUTING.profile, PROFILE_ROUTING.notifications],
    actions: ['close'],
    highlightedRoute: PROFILE_ROUTING.notifications,
    appendTag: false,
    trackingLabel: '[menu] notifications',
  },
  {
    id: FEATURES_ROUTING.contact,
    label: 'Contact',
    route: ['/', FEATURES_ROUTING.contact],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.contact,
    appendTag: false,
    trackingLabel: '[menu] contact',
  },
  {
    id: FEATURES_ROUTING.faq,
    label: 'FAQ',
    route: ['/', FEATURES_ROUTING.faq],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.faq,
    appendTag: false,
    trackingLabel: '[menu] faq',
  },
  {
    id: 'logout',
    label: UserLoggedInEntriesLabelEnum.LOGOUT,
    actions: ['close', 'logout'],
    appendTag: false,
    trackingLabel: '[menu] déconnecter',
  },
];

export const LegalGuardianLoggedMainEntries: UserLoggedInMenuEntry[] = [
  {
    id: FEATURES_ROUTING.faq,
    label: 'FAQ',
    route: ['/', FEATURES_ROUTING.faq],
    actions: ['close'],
    highlightedRoute: FEATURES_ROUTING.faq,
    trackingLabel: '[menu] faq',
  },
  {
    id: FEATURES_ROUTING.contact,
    label: 'Contact',
    route: ['/', FEATURES_ROUTING.contact],
    highlightedRoute: FEATURES_ROUTING.contact,
    actions: ['close'],
    trackingLabel: '[menu] contact',
  },
  {
    id: 'logout',
    label: UserLoggedInEntriesLabelEnum.LOGOUT,
    actions: ['close', 'logout'],
    trackingLabel: '[menu] déconnecter',
  },
];

export const LegalGuardianLoggedInEntries: UserLoggedInMenuEntry[] = UserLoggedInEntries.map((entry) => {
  if ((entry.id as ProfileRoutingType) === PROFILE_ROUTING.notifications) {
    return {
      ...entry,
      route: ['/', FEATURES_ROUTING.guardian, GUARDIAN_ROUTING.notifications],
      highlightedRoute: GUARDIAN_ROUTING.notifications,
    };
  }
  return entry;
});
