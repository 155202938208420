import { createReducer, on } from '@ngrx/store';
import { closeBurgerPanel, toggleBurgerPanel } from '@stores/panel/panel.actions';

export const panelFeatureKey = 'panel';

type PanelState = boolean;

const initialState: PanelState = false;

export const panelReducer = createReducer(
  initialState,
  on(toggleBurgerPanel, (isBurgerOpen) => !isBurgerOpen),
  on(closeBurgerPanel, () => false)
);
