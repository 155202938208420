import { getPermittedAttributes } from '../send-page-attributes.js';
import { instrumentPageLoadPhaseSpans } from './page-load-phase-spans.js';
import { defaultRouteResolver } from '../default-routing-provider.js';

class FullPageLoadPlugin {
    constructor(document, location, spanFactory, webVitals, onSettle, backgroundingListener, performance) {
        // if the page was backgrounded at any point in the loading process a page
        // load span is invalidated as the browser will deprioritise the page
        this.wasBackgrounded = false;
        this.document = document;
        this.location = location;
        this.spanFactory = spanFactory;
        this.webVitals = webVitals;
        this.onSettle = onSettle;
        this.performance = performance;
        backgroundingListener.onStateChange(state => {
            if (!this.wasBackgrounded && state === 'in-background') {
                this.wasBackgrounded = true;
            }
        });
    }
    configure(configuration) {
        // don't report a page load span if the option is turned off or the page was
        // backgrounded at any point in the loading process
        if (!configuration.autoInstrumentFullPageLoads || this.wasBackgrounded) {
            return;
        }
        let parentContext = null;
        const traceparentMetaTag = document.querySelector('meta[name="traceparent"]');
        if (traceparentMetaTag !== null && traceparentMetaTag.getAttribute('content')) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const traceparent = traceparentMetaTag.getAttribute('content');
            const [, traceId, parentSpanId] = traceparent.split('-');
            parentContext = {
                traceId,
                id: parentSpanId
            };
        }
        const span = this.spanFactory.startSpan('[FullPageLoad]', { startTime: 0, parentContext });
        const permittedAttributes = getPermittedAttributes(configuration.sendPageAttributes);
        const url = new URL(this.location.href);
        this.onSettle((endTime) => {
            if (this.wasBackgrounded)
                return;
            // ensure there's always a route on this span by falling back to the
            // default route resolver - the pipeline will ignore page load spans that
            // don't have a route
            const route = configuration.routingProvider.resolveRoute(url) || defaultRouteResolver(url);
            span.name += route;
            instrumentPageLoadPhaseSpans(this.spanFactory, this.performance, route, span);
            // Browser attributes
            span.setAttribute('bugsnag.span.category', 'full_page_load');
            span.setAttribute('bugsnag.browser.page.route', route);
            if (permittedAttributes.referrer)
                span.setAttribute('bugsnag.browser.page.referrer', this.document.referrer);
            if (permittedAttributes.title)
                span.setAttribute('bugsnag.browser.page.title', this.document.title);
            if (permittedAttributes.url)
                span.setAttribute('bugsnag.browser.page.url', url.toString());
            this.webVitals.attachTo(span);
            this.spanFactory.endSpan(span, endTime);
        });
    }
}

export { FullPageLoadPlugin };
