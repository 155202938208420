import { NgIf, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PremiumTagComponent } from '@commons/premium-tag/premium-tag.component';
import { FEATURES_ROUTING } from '@features/features.routing';
import { BurgerMenuService } from '@models/burger-menu/burger-menu-service.interface';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-burger-menu-item',
  templateUrl: './burger-menu-item.component.html',
  styleUrls: ['./burger-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PremiumTagComponent, RouterLink, RouterLinkActive, TitleCasePipe, NgIf, MatIconModule],
})
export class BurgerMenuItemComponent {
  readonly serviceSig = signal<BurgerMenuService | undefined>(undefined);
  @Input({ required: true })
  set service(service: BurgerMenuService) {
    this.serviceSig.set(service);
  }

  @Output() serviceClicked = new EventEmitter<void>();

  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #sanitizer = inject(DomSanitizer);

  readonly iconSig = computed(() => {
    const service = this.serviceSig();

    if (!service) {
      return undefined;
    }

    this.#matIconRegistry.addSvgIcon(service.id, this.#sanitizer.bypassSecurityTrustResourceUrl(service.icon));

    return service.id;
  });

  readonly linkSig = computed(() => {
    const service = this.serviceSig();

    if (!service) {
      return undefined;
    }

    return ['/', FEATURES_ROUTING.services, service.id];
  });

  readonly showPremiumTagSig = computed(() => {
    const service = this.serviceSig();

    if (!service) {
      return false;
    }

    return Boolean(service.isLocked);
  });

  trackServiceClicked(): void {
    trackEvent('menu', 'click', `[menu] service - ${this.serviceSig()?.id}`);
  }
}
