import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Partner } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { setTitleStrategyData } from '@stores/breadcrumbs/breadcrumbs.actions';
import { TitleStrategyData } from '@stores/breadcrumbs/breadcrumbs.reducer';

/**
 * /**
 * Provides a strategy for setting the page title after a router navigation.
 *
 * The built-in implementation traverses the router state snapshot and finds the deepest primary
 * outlet with `title` property. Given the `Routes` below, navigating to
 * `/base/child(popup:aux)` would result in the document title being set to "child".
 * ```
 * [
 *   {path: 'base', title: 'base', children: [
 *     {path: 'child', title: 'child'},
 *   ],
 *   {path: 'aux', outlet: 'popup', title: 'popupTitle'}
 * ]
 * ```
 * for deep title dynamic like jobs details page or event
 * ````
 *   data: {
 *             titlePath: 'job.title',
 *         }
 *```
  ⚠︎⚠️⚠️ Dont use Title service into component ⚠️⚠️⚠️
 */
@Injectable({ providedIn: 'root' })
export class LoyaltyTitleStrategy extends TitleStrategy {
  readonly #store = inject(Store);
  readonly #location = inject(Location);

  #titleStrategyData!: TitleStrategyData;

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    this.#resetTitleStrategyData();

    if (!(window as any).partner) {
      throw new Error('Partner not found in window object');
    }

    const productName = ((window as any).partner as Partner).productName;
    const partnerName = ((window as any).partner as Partner).name;
    const title = this.buildTitle(routerState);
    this.#store.dispatch(setTitleStrategyData({ titleStrategyData: this.#titleStrategyData }));

    if (title !== undefined) {
      this.title.setTitle(`${title} - ${productName} by ${partnerName}`);
      return;
    }

    this.title.setTitle(`${productName}`);
  }

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): any {
    const title = this.getDeepPropertyFromDataObject(snapshot) ?? super.getResolvedTitleForRoute(snapshot);
    const parentTitle = snapshot.parent?.title;
    return parentTitle && parentTitle !== title ? `${title} - ${parentTitle}` : title;
  }

  readonly getDeepPropertyFromDataObject = (snapshot: ActivatedRouteSnapshot): string | undefined => {
    const titlePath = snapshot.data.titlePath;

    if (titlePath) {
      const title =
        this.getDeepProperty(snapshot.data, titlePath) ?? this.getDeepProperty(this.#location.getState(), titlePath);
      this.#setTitleStrategyData(snapshot, title);
      return title;
    }

    return undefined;
  };

  /**
   *
   * @param from Object
   * @param selector string example: 'title' | 'job.title' | 'job.company.name'
   * @returns  string | undefined
   */
  readonly getDeepProperty = (from: any, selector: string): string =>
    selector
      // eslint-disable-next-line no-useless-escape
      .replace(/\[([^\[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t) => t !== '')
      .reduce((prev, cur) => prev?.[cur], from);

  #setTitleStrategyData(snapshot: ActivatedRouteSnapshot, title: string) {
    if (!this.#titleStrategyData) {
      this.#titleStrategyData = { urlPath: snapshot.url[snapshot.url.length - 1].path, title };
    }
  }

  #resetTitleStrategyData() {
    this.#store.dispatch(setTitleStrategyData({ titleStrategyData: null }));
    this.#titleStrategyData = null;
  }
}
