import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

const translateY0 = style({ transform: 'translateY(0)' });

export function slideDownOnEnter(timing: string): AnimationTriggerMetadata {
  return trigger('slideDownOnEnterAnimation', [
    transition(':enter', [style({ transform: 'translateY(-50%)' }), animate(`${timing} ease-out`, translateY0)]),
  ]);
}

export function slideUpOnLeave(timing: string): AnimationTriggerMetadata {
  return trigger('slideUpOnLeaveAnimation', [
    transition(':leave', [translateY0, animate(`${timing} ease-out`, style({ transform: 'translateY(-50%)' }))]),
  ]);
}
