/* eslint-disable no-console */
import { BugsnagHandler, GlobalErrorHandler } from './error-handler';
import { platformProviders } from './platform-specific-providers/providers';
import { APP_BASE_HREF, IMAGE_LOADER } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import '@angular/common/locales/global/fr';
import {
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, HAMMER_GESTURE_CONFIG, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  RouteReuseStrategy,
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { bugsnagBootstrapper } from '@bugsnag/angular-performance';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular/dist/esm2015';
import { bugsnagConfig, bugsnagPerformanceConfig } from '@config/bugsnag.config';
import { AppRoutes } from '@core/app-routing';
import { CoreComponent } from '@core/components/core/core.component';
import { WizbiiRouteReuseStrategy } from '@core/route-strategy/wizbii-route-strategy';
import { JwtService } from '@core/services/jwt.service';
import { MatPaginatorAlgolia } from '@core/services/mat-paginator/mat-paginator-algolia';
import { imaginaryLoader } from '@core/services/ng-optimized-image.imaginary';
import { PasswordStrengthService } from '@core/services/password/password-strength.service';
import { PWD_OPTIONS, PWD_RULES } from '@core/services/password/password-tokens';
import { LoyaltyTitleStrategy } from '@core/title-strategy/loyalty-title-strategy';
import { getCookieDomain, getPartnerDomain, providePartnerFactory } from '@core/utils/platform-utils';
import { environment } from '@environment';
import { loyaltyPasswordOptions, loyaltyPasswordRules } from '@models/password/password-strength';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import * as bankingProductsEffets from '@stores/banking-products/banking-products.effects';
import { bankingProductsFeatureKey, bankingProductsReducer } from '@stores/banking-products/banking-products.reducer';
import { breadcrumbReducer, breadcrumbsFeatureKey } from '@stores/breadcrumbs/breadcrumbs.reducer';
import * as childrenInfosEffects from '@stores/children-infos/children-infos.effects';
import { childrenInfosFeatureKey, childrenInfosReducer } from '@stores/children-infos/children-infos.reducer';
import * as communicationsEffets from '@stores/communications/communications.effects';
import { communicationsFeatureKey, communicationsReducer } from '@stores/communications/communications.reducer';
import { eventFullFeatureKey, eventFullReducer } from '@stores/event-full/event-full.reducer';
import * as featureFlagsEffets from '@stores/feature-flags/feature-flags.effects';
import { featureFlagsFeatureKey, featureFlagsReducer } from '@stores/feature-flags/feature-flags.reducer';
import * as highlightedServiceEffect from '@stores/highlighted-service/highlighted-service.effects';
import {
  highlightedServiceFeatureKey,
  highlightedServiceReducer,
} from '@stores/highlighted-service/highlighted-service.reducer';
import * as logoutJwtEffect from '@stores/jwt/logout-jwt.effects';
import { legalNoticesFeatureKey, legalNoticesReducer } from '@stores/legal-notices/legal-notices.reducer';
import * as legalPagesEffets from '@stores/legal-pages/legal-pages.effects';
import { legalPagesFeatureKey, legalPagesReducer } from '@stores/legal-pages/legal-pages.reducer';
import { onboardingFeatureKey, onboardingReducer } from '@stores/onboarding/onboarding.reducer';
import { panelFeatureKey, panelReducer } from '@stores/panel/panel.reducer';
import * as partnerEffects from '@stores/partner/partner.effects';
import { partnerFeatureKey, partnerReducer } from '@stores/partner/partner.reducer';
import * as paymentEffects from '@stores/payment/payment.effects';
import { paymentFeatureKey, paymentReducer } from '@stores/payment/payment.reducer';
import * as planEffects from '@stores/plan/plan.effects';
import { planFeatureKey, planReducer } from '@stores/plan/plan.reducer';
import * as profileEffets from '@stores/profile/profile.effects';
import { profileFeatureKey, profileReducer } from '@stores/profile/profile.reducer';
import { purchaseFeatureKey, purchaseReducer } from '@stores/purchase';
import * as purchaseEffects from '@stores/purchase/purchase.effects';
import * as serviceAuthorizationEffects from '@stores/service-authorization/authorization.effects';
import {
  serviceAuthorizationFeatureKey,
  serviceAuthorizationReducer,
} from '@stores/service-authorization/authorization.reducer';
import * as serviceConfigurationEffects from '@stores/service-configuration/service-configuration.effects';
import {
  serviceConfigurationKey,
  serviceConfigurationReducer,
} from '@stores/service-configuration/service-configuration.reducer';
import * as servicesEffets from '@stores/services/services.effects';
import { servicesFeatureKey, servicesReducer } from '@stores/services/services.reducer';
import * as themesEffects from '@stores/themes/themes.effects';
import { themesFeatureKey, themesReducer } from '@stores/themes/themes.reducer';
import { AlgoliaCredentialsService } from '@webservices/algolia/algolia-credentials.service';
import { PLATFORM_WEBSERVICES_TOKEN } from '@webservices/injection-tokens';
import {
  ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
  ALGOLIA_SERVICE_TOKEN,
  AlgoliaClientProvider,
  AlgoliaParamsInterface,
  CredentialsFetchers,
} from '@wizbii-utils/angular/algolia';
import { ImageService, WINDOW_PROVIDERS } from '@wizbii-utils/angular/core';
import { JWT_SERVICE_TOKEN, JwtInterceptorFn } from '@wizbii-utils/angular/jwt';
import { IMAGINARY_PIPE_CONFIG, ImaginaryPipeConfig } from '@wizbii-utils/angular/pipes';
import {
  JWT_STATE_CONFIG,
  NGRX_JWT_STATE_CONFIG,
  jwtDeleteEffect,
  jwtDeleteHardEffect,
  jwtFeatureKey,
  jwtReducer,
  jwtSetEffect,
  loadJwt,
} from '@wizbii-utils/angular/stores';
import {
  CONTACT_API_CONFIG,
  CityWebservice,
  ContactApiConfig,
  ContactWebservice,
  ContractWebservice,
  FILE_API_CONFIG,
  FileApiConfig,
  FileWebservice,
  JOB_API_CONFIG,
  JobApiConfig,
  PLACE_API_CONFIG,
  PlaceApiConfig,
} from '@wizbii-utils/angular/webservices';
import { ScrollToModule } from '@wizbii/ngx-scroll-to';
import { LangEnum } from '@wizbii/utils/models';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const client = Bugsnag.start(bugsnagConfig);

if (environment.platform === 'prod') {
  BugsnagPerformance.start(bugsnagPerformanceConfig);
}

bootstrapApplication(CoreComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    importProvidersFrom(QuicklinkModule),
    provideHttpClient(withFetch(), withInterceptors([JwtInterceptorFn])),
    provideRouter(
      AppRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        get scrollPositionRestoration() {
          const pathname = window.location.pathname;
          const regexp = /blegal\b|services$/;

          const params = new URLSearchParams(window.location.search);

          if (regexp.test(pathname) || params.has('noscroll')) {
            return 'disabled' as const;
          }

          return 'top' as const;
        },
        anchorScrolling: 'enabled',
      }),
      withPreloading(QuicklinkStrategy),
      withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      })
    ),
    bugsnagBootstrapper,
    provideCharts(withDefaultRegisterables()),
    provideStore({ router: routerReducer }),
    provideState({ name: jwtFeatureKey, reducer: jwtReducer }),
    provideState({ name: featureFlagsFeatureKey, reducer: featureFlagsReducer }),
    provideState({ name: communicationsFeatureKey, reducer: communicationsReducer }),
    provideState({ name: themesFeatureKey, reducer: themesReducer }),
    provideState({ name: panelFeatureKey, reducer: panelReducer }),
    provideState({ name: partnerFeatureKey, reducer: partnerReducer }),
    provideState({ name: bankingProductsFeatureKey, reducer: bankingProductsReducer }),
    provideState({ name: profileFeatureKey, reducer: profileReducer }),
    provideState({ name: planFeatureKey, reducer: planReducer }),
    provideState({ name: onboardingFeatureKey, reducer: onboardingReducer }),
    provideState({ name: legalPagesFeatureKey, reducer: legalPagesReducer }),
    provideState({ name: breadcrumbsFeatureKey, reducer: breadcrumbReducer }),
    provideState({ name: servicesFeatureKey, reducer: servicesReducer }),
    provideState({ name: highlightedServiceFeatureKey, reducer: highlightedServiceReducer }),
    provideState({ name: paymentFeatureKey, reducer: paymentReducer }),
    provideState({ name: purchaseFeatureKey, reducer: purchaseReducer }),
    provideState({ name: legalNoticesFeatureKey, reducer: legalNoticesReducer }),
    provideState({ name: childrenInfosFeatureKey, reducer: childrenInfosReducer }),
    provideState({ name: serviceConfigurationKey, reducer: serviceConfigurationReducer }),
    provideState({ name: eventFullFeatureKey, reducer: eventFullReducer }),
    provideState({ name: serviceAuthorizationFeatureKey, reducer: serviceAuthorizationReducer }),
    provideEffects(
      { loadJwt, jwtDeleteEffect, jwtSetEffect, jwtDeleteHardEffect },
      logoutJwtEffect,
      themesEffects,
      partnerEffects,
      featureFlagsEffets,
      communicationsEffets,
      bankingProductsEffets,
      profileEffets,
      planEffects,
      legalPagesEffets,
      servicesEffets,
      paymentEffects,
      purchaseEffects,
      highlightedServiceEffect,
      childrenInfosEffects,
      serviceConfigurationEffects,
      serviceAuthorizationEffects
    ),
    provideRouterStore({
      routerState: RouterState.Full,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    importProvidersFrom(ScrollToModule.forRoot()),
    importProvidersFrom(MatIconModule),
    importProvidersFrom(MatDialogModule),
    { provide: LOCALE_ID, useValue: LangEnum.fr },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: RouteReuseStrategy, useClass: WizbiiRouteReuseStrategy },
    { provide: TitleStrategy, useClass: LoyaltyTitleStrategy },
    WINDOW_PROVIDERS,
    AlgoliaClientProvider,
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsService: AlgoliaCredentialsService): CredentialsFetchers => ({
        [environment.algolia.clientName]: {
          fetchCredentials: () => algoliaCredentialsService.getCredentialsFromPartner(),
        },
      }),
      deps: [AlgoliaCredentialsService],
    },
    {
      provide: ALGOLIA_SERVICE_TOKEN,
      useValue: {
        applicationId: environment.algolia.applicationId,
        apiKey: environment.algolia.apiKey,
        prefix: environment.apiPlatform,
        options: {},
      } satisfies AlgoliaParamsInterface,
    },
    {
      provide: JWT_STATE_CONFIG,
      useFactory: providePartnerFactory((partner) => ({
        appEnvFqdn: getCookieDomain(partner),
        jwtCookieName: environment.cookieTokenName,
        expiryCookieName: environment.cookieTokenExpiryName,
      })),
    },
    {
      provide: NGRX_JWT_STATE_CONFIG,
      useFactory: providePartnerFactory((partner) => ({
        appEnvFqdn: getCookieDomain(partner),
        jwtCookieName: environment.cookieTokenName,
        expiryCookieName: environment.cookieTokenExpiryName,
      })),
    },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    {
      provide: IMAGE_LOADER,
      useValue: imaginaryLoader,
    },
    {
      provide: IMAGINARY_PIPE_CONFIG,
      useValue: {
        imaginaryUrl: environment.api.imaginary,
      } satisfies ImaginaryPipeConfig,
    },
    {
      provide: BugsnagHandler,
      useFactory: () => new BugsnagErrorHandler(client),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    FileWebservice,
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      } satisfies FileApiConfig,
    },
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      } satisfies PlaceApiConfig,
    },
    ContractWebservice,
    {
      provide: JOB_API_CONFIG,
      useValue: {
        baseUrl: environment.api.job,
      } satisfies JobApiConfig,
    },
    {
      provide: PLATFORM_WEBSERVICES_TOKEN,
      useFactory: providePartnerFactory((partner) => ({
        platformDomain: getPartnerDomain(partner),
      })),
    },
    PasswordStrengthService,
    {
      provide: PWD_RULES,
      useValue: loyaltyPasswordRules,
    },
    {
      provide: PWD_OPTIONS,
      useValue: loyaltyPasswordOptions,
    },
    ContactWebservice,
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
        locale: LangEnum.fr,
      } satisfies ContactApiConfig,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' } satisfies MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 0,
          exitDuration: 0,
        },
      } satisfies RippleGlobalOptions,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorAlgolia },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useValue: {
        overrides: {
          pan: { velocity: 0.4, threshold: 20 },
        },
      },
    },

    ...platformProviders,
  ],
}).catch((err) => console.error(err));
