export type MenuActions = 'close' | 'logout';

export interface UserLoggedInMenuEntry {
  id: string;
  label: string;
  route?: string[];
  actions: MenuActions[];
  highlightedRoute?: string;
  appendTag?: boolean;
  trackingLabel: `[menu] ${string}`;
}

export enum UserLoggedInEntriesLabelEnum {
  SERVICES = 'Services',
  PROFIL = 'Mes informations',
  LOGOUT = 'Se déconnecter',
}
