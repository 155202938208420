import { inject } from '@angular/core';
import { Partner } from '@loyalty-v3/libs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { init } from '@stores/partner/partner.actions';
import { map } from 'rxjs';

const getPartnerFromWindow = (): Partner => (window as any).partner;

export const loadPartner = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => getPartnerFromWindow()),
      map((partner) => init({ partner }))
    ),
  { functional: true }
);
