import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, HammerModule } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@commons/footer/footer.component';
import { NotificationsPanelComponent } from '@core/components/notifications-panel/notifications-panel.component';
import { BreadcrumbCoreService } from '@core/services/breadcrumb-core.service';
import { NpsCoreService } from '@core/services/nps-core.service';
import { PaymentCoreService } from '@core/services/payment-core.service';
import { TrackingCoreService } from '@core/services/tracking-core.service';
import { BreadcrumbsHeaderComponent } from '@domains/breadcrumbs-header/breadcrumbs-header.component';
import { FreshdeskButtonComponent } from '@domains/freshdesk/freshdesk-button/freshdesk-button.component';
import { IncentiveBannerComponent } from '@domains/incentive-banner/incentive-banner.component';
import { LegalNoticesComponent } from '@domains/legal-notices/legal-notices.component';
import { MainHeaderComponent } from '@domains/main-header/main-header.component';
import { environment } from '@environment';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import * as FromOnboardingSelectors from '@stores/onboarding/onboarding.selectors';
import { isBurgerOpen } from '@stores/panel/panel.selectors';
import { hasCoPlatform, selectPartner } from '@stores/partner/partner.selectors';
import { selectRouteData, selectRouteDataParam } from '@stores/router/router.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { isLogged } from '@wizbii-utils/angular/stores';
import { ScrollToConfigOptions, ScrollToModule, ScrollToService } from '@wizbii/ngx-scroll-to';
import { CookieService } from 'ngx-cookie-service';
import { hostBinding } from 'ngxtension/host-binding';
import { Observable, map } from 'rxjs';
import { distinctUntilChanged, shareReplay, take } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  imports: [
    AsyncPipe,
    BreadcrumbsHeaderComponent,
    FooterComponent,
    HammerModule,
    IncentiveBannerComponent,
    LegalNoticesComponent,
    MainHeaderComponent,
    MatIconModule,
    MatNativeDateModule,
    NotificationsPanelComponent,
    RouterOutlet,
    ScrollToModule,
    FreshdeskButtonComponent,
  ],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (partner$ | async; as partner) {
      <app-notifications-panel />

      @if (showIncentiveBanner()) {
        <app-incentive-banner class="incentive-banner" />
      }

      <button class="avoidance-link" (click)="handleAvoidanceLinkClicked()" type="button" obendy-button tabindex="0">
        Aller au contenu
      </button>

      <div class="layout" [class.layout--has-menu]="(showHeader$ | async) !== false">
        @if ((showHeader$ | async) !== false) {
          <app-main-header class="layout__header" />
        }

        <main class="layout__content" [class.layout__content--has-menu]="(showHeader$ | async) !== false" role="main">
          <div class="layout__content__body">
            @if ((showHeader$ | async) !== false && (showBreadcrumb$ | async) !== false) {
              <app-breadcrumbs-header
                class="layout__breadcrumbs"
                [id]="breadcrumbsId"
                [isLogged]="isLogged()"
                tabindex="0"
              />
            }
            <div [id]="mainContentId" tabindex="0">
              <router-outlet />
              <app-legal-notices class="layout__legal-notices" />
            </div>
          </div>

          @if ((showFooter$ | async) !== false) {
            <app-footer class="layout__footer" [partner]="partner" [isUserLogged]="isLogged()" />
          }
        </main>
        <app-freshdesk-button />
      </div>
    }
  `,
  styleUrls: ['./core.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent {
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #trackingService = inject(TrackingCoreService);
  readonly #breadcrumbService = inject(BreadcrumbCoreService);
  readonly #npsService = inject(NpsCoreService);
  readonly #paymentCoreService = inject(PaymentCoreService);
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);
  readonly #scrollToService = inject(ScrollToService);
  readonly #cookieService = inject(CookieService);

  readonly partner$ = selectIfDefined(this.#store, selectPartner);
  readonly #isBurgerPanelOpen$ = this.#store.select(isBurgerOpen);

  protected mainContentId = 'mainContentId' as const;
  protected breadcrumbsId = 'breadcrumbsId' as const;

  readonly #hasCoPlatform = this.#store.selectSignal(hasCoPlatform);
  readonly #isOnboardingDone = this.#store.selectSignal(FromOnboardingSelectors.isOnboardingDone);
  readonly isLogged = this.#store.selectSignal(isLogged);

  readonly #pageWithIncentiveBanner$ = this.#store.select(selectRouteData).pipe(
    map((data) => {
      if (!data) return false;
      return data['pageWithIncentiveBanner'] ?? false;
    })
  );

  readonly #pageWithIncentiveBanner = toSignal(this.#pageWithIncentiveBanner$, {
    initialValue: false,
  });

  readonly showIncentiveBanner = computed(
    () => this.isLogged() && this.#isOnboardingDone() && this.#hasCoPlatform() && this.#pageWithIncentiveBanner()
  );

  readonly showHeader$: Observable<boolean> = this.#store.select(selectRouteDataParam('showHeader')).pipe(
    map((showHeader) => (typeof showHeader === 'boolean' ? showHeader : true)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly showFooter$: Observable<boolean> = this.#store.select(selectRouteDataParam('showFooter')).pipe(
    map((showFooter) => (typeof showFooter === 'boolean' ? showFooter : true)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  readonly showBreadcrumb$: Observable<boolean> = this.#store.select(selectRouteDataParam('showBreadcrumb')).pipe(
    map((showBreadcrumb) => (typeof showBreadcrumb === 'boolean' ? showBreadcrumb : true)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor() {
    this.#trackingService.initializeTracking(this.#cookieService.check('loyalty_token'));
    this.#trackingService.initializeGdprAuthAndWaUserId();
    this.#setIconRegistry();

    this.#breadcrumbService.initBreadcrumbs();
    this.#initBlockAll();
    this.#paymentCoreService.initBillingInfos();
    this.#paymentCoreService.initPurchasableArticles();

    this.#npsService.initNPS();
  }

  readonly hoverEnabled = hostBinding('class.hover-on', signal(true));
  readonly hoverDisabled = hostBinding(
    'class.hover-off',
    computed(() => !this.hoverEnabled())
  );
  readonly blockAll = hostBinding('class.block-all', signal(false));
  /**
   * Class `focus-off` disables all outlines automatically.
   */
  readonly focusOutlineDisabled = hostBinding('class.focus-off', signal(false));

  get currentUrlIsDashboard(): boolean {
    return this.#router.url === `/${FEATURES_ROUTING.dashboard}`;
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled.set(evt.pointerType === 'mouse');
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  @HostListener('touchstart')
  onTouchStart(): void {
    this.hoverEnabled.set(false);
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled.set(true);
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled.set(false);
  }

  readonly #setIconRegistry = (): void => {
    this.#matIconRegistry.addSvgIconSet(
      this.#domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployAssetsUrl}/sprite.svg`)
    );
  };

  readonly #initBlockAll = (): void => {
    this.#isBurgerPanelOpen$.pipe(distinctUntilChanged()).subscribe((blockAll) => {
      this.blockAll.set(blockAll);
    });
  };

  protected readonly handleAvoidanceLinkClicked = (): void => {
    const mainContentElement: HTMLDivElement | null = document.querySelector(`#${this.mainContentId}`);
    const breadCrumbsElement: HTMLDivElement | null = document.querySelector(`#${this.breadcrumbsId}`);

    let config: ScrollToConfigOptions = {
      target: this.mainContentId,
      offset: 0,
    };

    let elementToFocus = mainContentElement;

    if (breadCrumbsElement) {
      config.target = this.breadcrumbsId;
      elementToFocus = breadCrumbsElement;
    }

    this.#scrollToService
      .scrollTo(config)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          if (elementToFocus) {
            elementToFocus.focus();
          }
        },
      });
  };
}
