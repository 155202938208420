import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PressEscapeKeyDirective } from '@commons/directives/press-escape-key.directive';
import { BurgerMenuComponent } from '@domains/burger-menu/burger-menu/burger-menu.component';
import { MainHeaderToolbarComponent } from '@domains/main-header/main-header-toolbar/main-header-toolbar.component';
import { Store } from '@ngrx/store';
import { fadeEnter, fadeLeave } from '@shared/animations/fade-animations';
import { slideDownOnEnter, slideUpOnLeave } from '@shared/animations/slide-animations';
import { isBurgerOpen } from '@stores/panel/panel.selectors';

@Component({
  selector: 'app-main-header',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <app-main-header-toolbar [shouldDisplayToolbar]="false">
      <app-burger-menu class="burger-menu" [class.burger-menu--open]="isBurgerPanelOpenSig()" />
    </app-main-header-toolbar>
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .burger-menu {
        position: fixed;
        inset: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%) scaleX(0);
        transition:
          transform 300ms ease-in-out,
          opacity 300ms;
        background-color: rgb(var(--loyal-body-background-color));

        &--open {
          opacity: 1;
          visibility: visible;
          transform-origin: left;
          transform: translateX(0) scaleX(1);
        }
      }
    `,
  ],
  animations: [fadeEnter('400ms'), fadeLeave('200ms'), slideDownOnEnter('500ms'), slideUpOnLeave('200ms')],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    BurgerMenuComponent,
    PressEscapeKeyDirective,
    A11yModule,
    MainHeaderToolbarComponent,
  ],
})
export class MainHeaderComponent {
  readonly #store = inject(Store);

  readonly isBurgerPanelOpenSig = this.#store.selectSignal(isBurgerOpen);
}
