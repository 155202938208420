import { Injectable, inject } from '@angular/core';
import { FEATURES_ROUTING } from '@features/features.routing';
import { UPSELL_ROUTING } from '@features/upsell/routes/upsell.routing';
import { AreasEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import {
  cleanSelectedBankingProducts,
  setAreaBankingProducts,
  setServiceBankingProduct,
} from '@stores/banking-products/banking-products.actions';

type WhitelistedPagesKeys = 'dashboard' | 'communications' | 'services' | 'bankingProducts';

const WHITELISTED_PAGES: Record<WhitelistedPagesKeys, string> = {
  dashboard: FEATURES_ROUTING.dashboard,
  communications: FEATURES_ROUTING.communications,
  services: FEATURES_ROUTING.services,
  bankingProducts: UPSELL_ROUTING.bankingProducts,
} as const;

@Injectable({ providedIn: 'root' })
export class BankingProductsService {
  readonly #store = inject(Store);

  readonly setSelectedBankingProducts = (url: string) => {
    if (url.endsWith(WHITELISTED_PAGES.dashboard)) {
      return this.#store.dispatch(setAreaBankingProducts({ area: AreasEnum.DASHBOARD }));
    }

    if (url.includes(WHITELISTED_PAGES.communications)) {
      return this.#store.dispatch(setAreaBankingProducts({ area: AreasEnum.COMMUNICATIONS }));
    }

    if (url.includes(WHITELISTED_PAGES.services)) {
      const serviceId = this.#getServiceIdFromUrl(url);
      if (serviceId) {
        return this.#store.dispatch(setServiceBankingProduct({ serviceId }));
      }
    }

    return this.#store.dispatch(cleanSelectedBankingProducts());
  };

  readonly #getServiceIdFromUrl = (url: string): string | null => {
    const regex = new RegExp(FEATURES_ROUTING.services + '/(.[^/?]*)');
    const match = regex.exec(url);
    return match?.[1] ?? null;
  };
}
