import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, signal } from '@angular/core';

@Component({
  selector: 'app-burger-button-menu',
  imports: [CommonModule],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <button
      class="burger-button-menu"
      [class.burger-button-menu--not-logged]="!isLogged"
      [attr.aria-modal]="isMenuOpenSig()"
      [title]="titleAndAriaLabel"
      [attr.aria-label]="titleAndAriaLabel"
      (click)="togglePanel.emit()"
      #openCloseButton
      aria-controls="burger-menu"
      type="button"
    >
      <svg
        class="burger-button-menu__svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="currentColor"
        aria-hidden="true"
        focusable="false"
      >
        <g class="burger-button-menu__svg__icon">
          <rect
            class="burger-button-menu__svg__icon--line burger-button-menu__svg__icon--line-top"
            x="3"
            y="3"
            rx="1"
            width="26"
            height="2"
          />
          <rect
            class="burger-button-menu__svg__icon--line burger-button-menu__svg__icon--line-middle"
            x="3"
            y="9"
            rx="1"
            width="26"
            height="2"
          />

          <rect
            class="burger-button-menu__svg__icon--line burger-button-menu__svg__icon--line-bottom"
            x="3"
            y="16"
            rx="1"
            width="26"
            height="2"
          />
          <path
            class="burger-button-menu__svg__icon--rect"
            d="M9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9C3 5.68629 5.68629 3 9 3Z"
            stroke-width="2"
            fill="none"
            stroke="currentColor"
          />
        </g>
        <g class="burger-button-menu__svg__icon--text" fill="currentColor">
          <path
            fill="currentColor"
            d="m1.01,30.22v-6.9h1.05l3.02,5.04h-.55l2.97-5.04h1.05v6.9s-1.2,0-1.2,0v-5s.25,0,.25,0l-2.52,4.21h-.57l-2.56-4.21h.3v5h-1.22Z"
          />
          <path
            fill="currentColor"
            d="m11.61,26.18h3.43v1.05h-3.43v-1.05Zm.1,2.96h3.89v1.07h-5.18v-6.9h5.04v1.07h-3.76v4.75Z"
          />
          <path
            fill="currentColor"
            d="m17.03,30.22v-6.9h1.06l4.33,5.31h-.52v-5.31h1.27v6.9h-1.05l-4.33-5.31h.52v5.31h-1.27Z"
          />
          <path
            fill="currentColor"
            d="m27.98,30.31c-.95,0-1.69-.27-2.23-.8-.53-.53-.8-1.3-.8-2.31v-3.89h1.28v3.85c0,.71.15,1.23.45,1.55.31.32.74.48,1.3.48s.99-.16,1.29-.48c.3-.32.45-.84.45-1.55v-3.85h1.26v3.89c0,1.01-.27,1.77-.81,2.31-.53.53-1.27.8-2.21.8Z"
          />
        </g>
      </svg>
    </button>
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .burger-button-menu {
        background-color: transparent;
        font-size: 0.625rem;
        border: none;
        cursor: pointer;

        &__svg {
          width: 32px;
          height: 32px;
          color: var(--burger-button-color, white);

          &__icon {
            &--rect {
              stroke-dasharray: 100;
              stroke-dashoffset: 100;
            }

            &--line {
              transform-origin: center;
            }

            &--line-middle {
              opacity: 1;
            }
          }
        }

        &[aria-modal='true'] {
          .burger-button-menu__svg__icon--text {
            path {
              transform: translateY(50%);
            }
          }

          .burger-button-menu__svg__icon--line-top,
          .burger-button-menu__svg__icon--line-bottom {
            y: 15px;
            transform: scaleX(0.5);
          }

          .burger-button-menu__svg__icon--line-top {
            rotate: 45deg;
          }

          .burger-button-menu__svg__icon--line-bottom {
            rotate: -45deg;
          }

          .burger-button-menu__svg__icon--rect {
            stroke-dashoffset: 0;
          }

          .burger-button-menu__svg__icon--line-middle {
            transform: translateX(50%);
            opacity: 0;
          }
        }

        &--not-logged {
          @include breakpoints.minWidth('xl') {
            display: none;
          }
        }

        @include breakpoints.minWidth('md') {
          --burger-button-color: black;
        }

        @include breakpoints.minWidth('xl') {
          --burger-button-color: black;

          &__svg {
            &__icon {
              &--rect {
                transition: stroke-dashoffset 200ms ease-out;
                transition-delay: 0;
              }

              &--text {
                path {
                  transition: transform 0.5s ease-out;
                }

                @for $i from 1 through 4 {
                  path:nth-child(#{$i}) {
                    transition-delay: calc(0.1s + 0.03s * #{$i});
                  }
                }
              }

              &--line {
                transition:
                  y 200ms ease-in 200ms,
                  rotate 200ms ease-in 200ms,
                  transform 200ms ease-in 200ms;
              }

              &--line-middle {
                transition:
                  opacity 0ms ease-in 490ms,
                  transform 200ms ease-in 325ms;
              }
            }
          }

          &[aria-modal='true'] {
            .burger-button-menu__svg__icon--line {
              transition:
                y 200ms ease-in,
                rotate 200ms ease-in 200ms,
                opacity 0ms 113ms,
                transform 200ms ease-in 400ms;
            }

            .burger-button-menu__svg__icon--rect {
              transition: stroke-dashoffset 0.6s ease-out;
              transition-delay: 0.4s;
            }
          }
        }
      }
    `,
  ],
  standalone: true,
})
export class BurgerButtonMenuComponent {
  @ViewChild('openCloseButton') openCloseButton?: ElementRef<HTMLElement>;

  @Input() isLogged = false;

  readonly isMenuOpenSig = signal(false);
  @Input()
  set isMenuOpen(isMenuOpen: boolean) {
    this.isMenuOpenSig.set(isMenuOpen);
    if (isMenuOpen && this.openCloseButton?.nativeElement) {
      this.openCloseButton.nativeElement.focus();
    }
  }
  @Output() togglePanel = new EventEmitter<void>();

  get titleAndAriaLabel(): string {
    return this.isMenuOpen ? 'Fermer le menu principal' : 'Ouvrir le menu principal';
  }
}
