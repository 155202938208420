<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (serviceSig(); as service) {
  <a
    class="burger-menu-item"
    [attr.aria-labelledby]="service.id"
    [routerLink]="linkSig()"
    [attr.aria-label]="'Accéder au service de ' + service.title + ', ' + service.name"
    (click)="serviceClicked.emit(); trackServiceClicked()"
    #rla="routerLinkActive"
    role="menuitem"
    routerLinkActive
  >
    <div class="burger-menu-item__link" [class.active-link]="rla.isActive">
      <span class="burger-menu-item__link__icon">
        @if (iconSig(); as icon) {
          <mat-icon [svgIcon]="icon" />
        }
      </span>

      <div class="burger-menu-item__link__content">
        <p class="burger-menu-item__link__content__title" obendy-text-bold-font>
          {{ service.title | titlecase }}
        </p>
        <p class="burger-menu-item__link__content__name" obendy-text-bold-font>{{ service.name }}</p>
      </div>
    </div>

    @if (showPremiumTagSig()) {
      <app-premium-tag class="burger-menu-item__premium-tag" [noLabelOnSmallDevice]="true" />
    }

    <mat-icon class="burger-menu-item__arrow-next" svgIcon="arrow-right-menu" />
  </a>
}
