import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FAV_ONLY, LogoComponent } from '@commons/logo/logo.component';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { BurgerButtonMenuComponent } from '@domains/burger-menu/burger-button-menu/burger-button-menu.component';
import { FEATURES_ROUTING } from '@features/features.routing';
import { AvatarSizeEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { fadeEnter, fadeLeave } from '@shared/animations/fade-animations';
import { slideDownOnEnter, slideUpOnLeave } from '@shared/animations/slide-animations';
import { closeBurgerPanel, toggleBurgerPanel } from '@stores/panel/panel.actions';
import { isBurgerOpen } from '@stores/panel/panel.selectors';
import { hasCoPlatform, selectPartner } from '@stores/partner/partner.selectors';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { selectRouteData } from '@stores/router/router.selectors';
import { isLogged } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';
import { map } from 'rxjs';

interface MainMenuToolbar {
  isBurgerMenuOpen: boolean;
  isMobileHeaderHidden: boolean;
  isMenuAside: boolean;
  showHeader: boolean;
  isLogged: boolean;
  mobileProductIcon?: string;
  desktopProductIcon?: string;
  isMobile: boolean;
  showBurgerButton: boolean;
  showLogoutButton: boolean;
  forceShowToolbar: boolean;
  isCurrentUserAGuardian: boolean;
  withIncentiveToolBar: boolean;
}

@Component({
  selector: 'app-main-header-toolbar',
  standalone: true,
  imports: [BurgerButtonMenuComponent, LogoComponent, RouterLink, RouterLinkActive, NgTemplateOutlet],
  animations: [fadeEnter('400ms'), fadeLeave('200ms'), slideDownOnEnter('500ms'), slideUpOnLeave('200ms')],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (mainHeaderToolbarComponentSig(); as mainHeaderToolbar) {
      @if (mainHeaderToolbar.isMobile) {
        @if (!mainHeaderToolbar.isBurgerMenuOpen || shouldDisplayToolbar) {
          <section
            class="toolbar"
            [class.hidden]="mainHeaderToolbar.isMenuAside"
            [@fadeEnterAnimation]
            [@fadeLeaveAnimation]
            [@slideDownOnEnterAnimation]
            [@slideUpOnLeaveAnimation]
          >
            <ng-container *ngTemplateOutlet="toolbar" />
          </section>
        }
      } @else {
        @if (!mainHeaderToolbar.isBurgerMenuOpen || shouldDisplayToolbar) {
          <section
            class="toolbar"
            [class.with-incentive-banner]="mainHeaderToolbar.withIncentiveToolBar"
            [class.hidden]="mainHeaderToolbar.isMenuAside"
            [class.small-height-no-header]="!mainHeaderToolbar.showHeader"
          >
            <ng-container *ngTemplateOutlet="toolbar" />
          </section>
        }
      }

      <ng-content />

      <ng-template #toolbar>
        @if (mainHeaderToolbar.showHeader) {
          <div class="toolbar__header" [@fadeEnterAnimation]>
            <!-- BURGER MENU BUTTON -->
            @if (mainHeaderToolbar.showBurgerButton) {
              <app-burger-button-menu
                [isMenuOpen]="mainHeaderToolbar.isBurgerMenuOpen"
                [isLogged]="mainHeaderToolbar.isLogged"
                (togglePanel)="toggleBurgerPanel()"
              />
            }
          </div>
        }

        <a
          class="toolbar__dashboard-link"
          [routerLink]="['/', FeaturesRouting.dashboard]"
          (click)="closeBurgerPanel('[navigation-menu] logo-large')"
          title="Aller à la page d'accueil"
        >
          <img
            class="toolbar__dashboard-link__icon--mobile"
            [src]="mainHeaderToolbar.mobileProductIcon"
            alt=""
            aria-hidden="true"
          />
          <img
            class="toolbar__dashboard-link__icon--desktop"
            [src]="mainHeaderToolbar.desktopProductIcon"
            alt=""
            aria-hidden="true"
            inline="true"
          />
        </a>

        <div class="toolbar__footer">
          <a
            class="btn-clean"
            [routerLink]="['/', FeaturesRouting.dashboard]"
            [tabindex]="mainHeaderToolbar.isCurrentUserAGuardian ? -1 : 0"
            (click)="closeBurgerPanel('[navigation-menu] logo-square')"
            aria-label="Aller à la page d'accueil"
          >
            <app-logo
              [desktopLogoBehavior]="LOGO_BEHAVIOR_FAV_ONLY"
              [mobileLogoBehavior]="LOGO_BEHAVIOR_FAV_ONLY"
              [favForcedWidth]="48"
              [favForcedHeight]="48"
            />
          </a>
        </div>
      </ng-template>
    }
  `,
  styleUrls: ['./main-header-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderToolbarComponent {
  readonly #store = inject(Store);
  readonly #mdBreakpointSig = toSignal(inject(BreakpointsService).md$);

  readonly #pageWithIncentiveBanner$ = this.#store.select(selectRouteData).pipe(
    map((data) => {
      if (!data) return false;
      return data['pageWithIncentiveBanner'] ?? false;
    })
  );

  readonly #pageWithIncentiveBanner = toSignal(this.#pageWithIncentiveBanner$, { initialValue: false });
  readonly #hasCoPlatform = this.#store.selectSignal(hasCoPlatform);

  readonly #showIncentiveBanner = computed(() => {
    const pageWithIncentiveBanner = this.#pageWithIncentiveBanner();
    const hasCoPlatform = this.#hasCoPlatform();

    return pageWithIncentiveBanner && hasCoPlatform;
  });

  @Input() shouldDisplayToolbar = true;

  readonly #isBurgerPanelOpenSig = this.#store.selectSignal(isBurgerOpen);
  readonly #isLoggedSig = this.#store.selectSignal(isLogged);
  readonly partnerSig = this.#store.selectSignal(selectPartner);
  readonly #isLegalGuardianSig = this.#store.selectSignal(isLegalGuardian);

  readonly #isMenuAsideSig = signal(false);
  readonly #isMobileHeaderHiddenSig = signal(false);
  readonly #showHeaderSig = signal(true);
  readonly #forceShowToolbarSig = signal(false);

  readonly FeaturesRouting = FEATURES_ROUTING;
  readonly SmallAvatarSize = AvatarSizeEnum.small;
  readonly LOGO_BEHAVIOR_FAV_ONLY = FAV_ONLY;

  readonly mainHeaderToolbarComponentSig = computed(() => {
    const isBurgerMenuOpen = !!this.#isBurgerPanelOpenSig();
    const isMobileHeaderHidden = !!this.#isMobileHeaderHiddenSig();
    const isMenuAside = !!this.#isMenuAsideSig();
    const showHeader = !!this.#showHeaderSig();
    const isLogged = !!this.#isLoggedSig();
    const forceShowToolbar = !!this.#forceShowToolbarSig();
    const partner = this.partnerSig();
    const mdBreakpoint = !!this.#mdBreakpointSig();
    const withIncentiveToolBar = this.#showIncentiveBanner();
    const isLegalGuardian = !!this.#isLegalGuardianSig();

    return {
      isBurgerMenuOpen,
      isMobileHeaderHidden,
      isMenuAside,
      showHeader,
      isLogged,
      withIncentiveToolBar,
      mobileProductIcon: partner?.partnerConfiguration.assets.logos.productLogoWhite.url,
      desktopProductIcon: partner?.partnerConfiguration.assets.logos.productLogo.url,
      isMobile: !mdBreakpoint,
      showBurgerButton: isLogged,
      showLogoutButton: isLegalGuardian,
      forceShowToolbar,
      isCurrentUserAGuardian: isLegalGuardian,
    } satisfies MainMenuToolbar;
  });

  readonly toggleBurgerPanel = (): void => {
    const isMenuOpen = this.#isBurgerPanelOpenSig();
    trackEvent('header', 'click', `[navigation-menu] menu - ${isMenuOpen ? 'close' : 'open'}`);
    this.#store.dispatch(toggleBurgerPanel());
  };

  readonly closeBurgerPanel = (trackingScope: string): void => {
    trackEvent('header', 'click ' + trackingScope);
    this.#store.dispatch(closeBurgerPanel());
  };
}
