import { Component, computed, inject } from '@angular/core';
import { TargetEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';

@Component({
  selector: 'app-login-signin-buttons',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav aria-label="Activation et connexion" role="navigation">
      <ul class="login-actions">
        @if (signUpSig(); as signUp) {
          <li>
            <a [href]="signUp.url" [target]="signUp.target" obendy-button appearance="outline">
              {{ signUp.label }}
            </a>
          </li>
        }
        @if (signInSig(); as signIn) {
          <li>
            <a [href]="signIn.url" [target]="signIn.target" obendy-button>
              {{ signIn.label }}
            </a>
          </li>
        }
      </ul>
    </nav>
  `,
  styles: [
    `
      .login-actions {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    `,
  ],
  standalone: true,
})
export class LoginSigninButtonsComponent {
  readonly #store = inject(Store);

  readonly #partnerSig = this.#store.selectSignal(selectPartner);

  readonly signInSig = computed(() => {
    const partner = this.#partnerSig();
    if (!partner?.partnerConfiguration?.signInCta) {
      return;
    }

    return {
      ...partner.partnerConfiguration.signInCta,
      target: partner.partnerConfiguration.signInCta?.target ?? TargetEnum.self,
    };
  });

  readonly signUpSig = computed(() => {
    const partner = this.#partnerSig();
    if (!partner) {
      return;
    }

    return partner.partnerConfiguration?.signUpCta
      ? {
          ...partner.partnerConfiguration.signUpCta,
          target: partner.partnerConfiguration.signUpCta?.target ?? TargetEnum.self,
        }
      : null;
  });
}
